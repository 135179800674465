import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { selectUser } from '../../state/auth/auth.slice';

interface RedirectIfAuthedProps {
  NotAuthedComponent: React.FC;
  authedPath: string;
}

/**
 * Redirects to the specified path if the user is authenticated.
 */
const RedirectIfAuthed = ({
  NotAuthedComponent,
  authedPath,
}: RedirectIfAuthedProps) => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate(authedPath);
    }
  }, [user, authedPath, navigate]);

  return user ? null : <NotAuthedComponent />;
};

export default RedirectIfAuthed;
