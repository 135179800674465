import { createSlice } from '@reduxjs/toolkit';
import { checkHealth } from '../health/health.slice';
import { RootState } from '../store';
import { RejectedAction } from '../state.types';

interface ErrorState {
  message: string | null;
}

const initialState: ErrorState = {
  message: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is RejectedAction =>
        [checkHealth.rejected.type].includes(action.type),
      (state, action) => {
        if (action.meta.aborted) return;
        state.message = action.error.message;
      },
    );
  },
});

/**
 * Reducer
 */
export const errorReducer = errorSlice.reducer;

/**
 * Selectors
 */
export const selectErrorMessage = (state: RootState) => state.error.message;
