import { useEffect } from 'react';

export const BODY_LOCK_CACHE = { current: 0 };
const cn = 'overflow-hidden';

/**
 * BodyLock component
 */
const BodyLock = () => {
  useEffect(() => {
    BODY_LOCK_CACHE.current++;
    document.body.classList.add(cn);

    return () => {
      BODY_LOCK_CACHE.current--;

      if (BODY_LOCK_CACHE.current === 0) {
        document.body.classList.remove(cn);
      }
    };
  }, []);
  return null;
};
export default BodyLock;
