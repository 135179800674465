import { PropsWithChildren, useEffect } from 'react';

/**
 * Upstream analytics
 */
const Analytics = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    // TODO: Add analytics
  }, []);

  return <>{children}</>;
};
export default Analytics;
