import { RouteObject } from 'react-router-dom';
import App from '../components/App/App';
import RedirectIfAuthed from '../components/RedirectIfAuthed/RedirectIfAuthed';
import RedirectIfNotAuthed from '../components/RedirectIfNotAuthed/RedirectIfNotAuthed';
import Redirect from '../components/Redirect/Redirect';
import AccountPage from './AccountPage/AccountPage';
import AccountsPage from './AccountsPage/AccountsPage';
import BudgetItemPage from './BudgetItemPage/BudgetItemPage';
import BudgetPage from './BudgetPage/BudgetPage';
import HomePage from './HomePage/HomePage';
import SettingsPage from './SettingsPage/SettingsPage';
import TransactionPage from './TransactionPage/TransactionPage';
import TransactionSettingsPage from './TransactionSettingsPage/TransactionSettingsPage';
import TransactionsPage from './TransactionsPage/TransactionsPage';

const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <RedirectIfAuthed
        NotAuthedComponent={HomePage}
        authedPath='/app/transactions'
      />
    ),
  },
  // QUESTION: can this be lazy loaded?
  {
    path: '/app',
    element: <RedirectIfNotAuthed AuthedComponent={App} notAuthedPath='/' />,
    children: [
      {
        path: 'transactions',
        element: <TransactionsPage />,
        children: [
          {
            path: ':id',
            element: <TransactionPage />,
          },
        ],
      },
      {
        path: 'budget',
        element: <BudgetPage />,
        children: [
          {
            path: ':item',
            element: <BudgetItemPage />,
          },
        ],
      },
      {
        path: 'accounts',
        element: <AccountsPage />,
        children: [
          {
            path: ':account',
            element: <AccountPage />,
          },
        ],
      },
      {
        path: 'settings',
        element: <SettingsPage />,
        children: [
          {
            path: 'transaction',
            element: <TransactionSettingsPage />,
          },
          // TODO: Add more settings pages here
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Redirect to='/' />,
  },
];

export default routes;
