import { Component, ErrorInfo, FC, PropsWithChildren } from 'react';

export interface FallbackUIProps {
  errorMessage: string;
}

interface ErrorBoundaryProps extends PropsWithChildren {
  fallbackUI: FC<FallbackUIProps>;
}

interface ErrorBoundaryState {
  error: null | Error;
}

/**
 * App error boundary
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  /**
   * Instance constructor
   */
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  /**
   * Update state in response to an error
   */
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  /**
   * Extra logic, for example, to log the error to an analytics service
   */
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: Log error to an error reporting service
    console.error('ErrorBoundary caught an error:', error, errorInfo);
  }

  /**
   * Render the fallback UI if an error occurs, otherwise render the children
   */
  render() {
    const FallbackUI = this.props.fallbackUI;

    if (this.state.error) {
      return <FallbackUI errorMessage={this.state.error.message} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
