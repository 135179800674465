import { PropsWithChildren } from 'react';
import { Toaster } from 'react-hot-toast';
import { toastOptions } from '../../utils/constants';

/**
 * TODO:
 *
 * - Add layout
 */
const Layout = ({ children }: PropsWithChildren) => {
  return (
    <main>
      {children}
      <Toaster toastOptions={toastOptions} />
    </main>
  );
};

export default Layout;
