import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AppTabs from '../AppTabs/AppTabs';

/**
 * Main app component after authentication
 */
const App = () => {
  const navigate = useNavigate();
  // if navigating to /app, replace with /app/transactions
  useEffect(() => {
    if (window.location.pathname === '/app') {
      navigate('/app/transactions');
    }
  }, [navigate]);

  return (
    <div className='mx-auto flex h-dvh max-w-screen-sm flex-col bg-neutral-800'>
      <Outlet />
      <AppTabs />
    </div>
  );
};

export default App;
