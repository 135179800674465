import { useEffect } from 'react';
import * as auth from 'netlify-identity-widget';
import Icons from '../../components/Icons/Icons';
import { login } from '../../state/auth/auth.slice';
import { useAppDispatch } from '../../hooks/redux';

/**
 * Home page component
 */
const HomePage = () => {
  const dispatch = useAppDispatch();

  // istanbul ignore next
  useEffect(() => {
    auth.on('login', user => {
      dispatch(login(structuredClone(user)));
      auth.close();
    });

    return () => {
      auth.off('login');
    };
  }, [dispatch]);

  return (
    <div className='min-h-dvh p-4 xs:p-8'>
      <div className='mx-auto max-w-screen-sm'>
        {/* logo */}
        <Icons.LogoAndText
          title='Budget Recon Logo'
          className='mx-auto mb-8 h-60 w-60'
        />

        <h1 className='mb-2 text-center text-2xl text-neutral-300'>
          Brainy Budget
        </h1>

        <button
          onClick={
            // istanbul ignore next
            () => auth.open('login')
          }
          className='w-full rounded-full border-4 border-yellow-600 px-2 py-4 text-lg font-bold text-yellow-600 transition-colors hover:bg-yellow-600 hover:text-black'
        >
          Login
        </button>
      </div>
    </div>
  );
};
export default HomePage;
