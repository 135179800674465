import { Link, useLocation } from 'react-router-dom';
import Icons from '../Icons/Icons';

export const tabs = [
  { Icon: Icons.Plus, label: 'transactions' },
  { Icon: Icons.Spreadsheet, label: 'budget' },
  { Icon: Icons.Building, label: 'accounts' },
  { Icon: Icons.Cog, label: 'settings' },
];

/**
 * AppTabs component.
 */
const AppTabs = () => {
  const location = useLocation();
  const currentTab = location.pathname.split('/').pop();

  return (
    <div className='mt-auto grid grid-cols-4'>
      {tabs.map(({ Icon, label }) => (
        <Link
          key={label}
          to={`/app/${label}`}
          className='flex grow flex-col items-center justify-center overflow-hidden bg-neutral-900 px-2 py-5 transition-colors'
        >
          <Icon
            className={`mx-auto h-6 w-6 ${currentTab === label ? 'stroke-blue-500' : 'stroke-neutral-400'}`}
          />
          <p
            className={`text-[10px] capitalize xs:text-xs ${currentTab === label ? 'text-blue-500' : 'text-neutral-400'}`}
          >
            {label}
          </p>
        </Link>
      ))}
    </div>
  );
};
export default AppTabs;
