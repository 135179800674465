import React, { lazy, FC, PropsWithChildren } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import netlifyIdentity, { init } from 'netlify-identity-widget';
import { store } from './state/store';
import reportWebVitals from './reportWebVitals';
import Analytics from './components/Analytics/Analytics';
import Layout from './components/Layout/Layout';
import Routes from './components/Routes/Routes';

import './index.css';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Oops from './components/Oops/Oops';
import AppInit from './components/AppInit/AppInit';
import Spinner from './components/Spinner/Spinner';

window.netlifyIdentity = netlifyIdentity;
init({ logo: false, namePlaceholder: 'Your full name' });

const container = document.getElementById('root')!;
const root = createRoot(container);

/**
 * Render the app with the given Analytics component.
 */
const render = (Analytics: FC<PropsWithChildren>) =>
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ErrorBoundary fallbackUI={Oops}>
          <Provider store={store}>
            <Analytics>
              <AppInit>
                <Layout>
                  <Routes />
                </Layout>
              </AppInit>
            </Analytics>
            <Spinner fullScreen />
          </Provider>
        </ErrorBoundary>
      </BrowserRouter>
    </React.StrictMode>,
  );

if (process.env.NODE_ENV === 'production') {
  render(Analytics);
} else {
  const LocalAnalytics = lazy(() => import('./components/Analytics/Analytics'));
  render(LocalAnalytics);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(console.log);
}
