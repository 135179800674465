import { Outlet, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Icons from '../../components/Icons/Icons';
import TransactionsTabs from '../../components/TransactionsTabs/TransactionsTabs';
import { QueryParamKey, TransactionsTab } from '../../utils/constants';

/**
 * Transactions page.
 *
 * TODO:
 *
 * - Add search slider and functionality
 * - Add month navigation
 */
const TransactionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dateStringArr = new Date().toDateString().split(' ');
  const curMonthAndYear = `${dateStringArr[1]} ${dateStringArr[3]}`;
  const searchParamValue = searchParams.get(
    QueryParamKey.TransactionsTab,
  ) as TransactionsTab | null;

  /**
   * Validate search param
   */
  useEffect(() => {
    const isSearchParamValid = Object.values<string>(TransactionsTab).includes(
      searchParamValue || '',
    );

    if (!searchParamValue || !isSearchParamValid) {
      setSearchParams(
        prev => {
          prev.set(QueryParamKey.TransactionsTab, TransactionsTab.Daily);
          return prev;
        },
        { replace: true },
      );
    }
  }, [searchParamValue, searchParams, setSearchParams]);

  return (
    <div className='grow overflow-auto'>
      <div className='relative mb-2 text-neutral-300'>
        {/* header */}
        <h1 className='p-2 text-center font-bold'>Transactions</h1>
        <button className='absolute right-0 top-0 p-2' title='Search'>
          <Icons.SearchIcon className='h-5 w-5' />
        </button>
      </div>

      {/* current month */}
      <div className='mb-2 flex items-center justify-between text-neutral-300'>
        <button className='p-2' title='Previous month'>
          <Icons.ChevronLeft className='h-5 w-5' />
        </button>
        <h2>{curMonthAndYear}</h2>
        <button className='p-2' title='Next month'>
          <Icons.ChevronRight className='h-5 w-5' />
        </button>
      </div>

      {/* transactions tabs */}
      <TransactionsTabs
        searchParam={searchParams.get(QueryParamKey.TransactionsTab)}
      />

      <Outlet />
    </div>
  );
};
export default TransactionsPage;
