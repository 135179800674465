import { FallbackUIProps } from '../ErrorBoundary/ErrorBoundary';

/**
 * Oops component
 * @description This component is used as a fallback UI in the ErrorBoundary component.
 */
const Oops = ({ errorMessage }: FallbackUIProps) => {
  return (
    <div className='text-neutral-300'>
      <h1>Oops! Something went wrong.</h1>
      <code>{errorMessage}</code>
    </div>
  );
};
export default Oops;
