import cn from 'classnames';
import { useAppSelector } from '../../hooks/redux';
import { selectIsLoading } from '../../state/loading/loading.slice';
import Icons from '../Icons/Icons';
import BodyLock from '../BodyLock/BodyLock';

const iconCNs = 'absolute h-40 w-40';
const fullScreenCNs = 'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2';
export const SPINNER_MASK = 'spinner-mask';

interface SpinnerProps {
  fullScreen?: boolean;
}

/**
 * Spinner component
 */
const Spinner = ({ fullScreen = false }: SpinnerProps) => {
  const isLoading = useAppSelector(selectIsLoading);
  if (!isLoading) return null;

  /**
   * Spinner content (not full screen)
   */
  const SpinnerContent = ({ classNames }: { classNames?: string }) => (
    <div className='relative' role='presentation'>
      <div className={cn(fullScreen && 'animate-loading-icon')}>
        <Icons.Sight
          className={cn(iconCNs, classNames, {
            'animate-loading-icon': !fullScreen,
          })}
        />
      </div>
      <Icons.DollarSign className={cn(iconCNs, classNames)} />
    </div>
  );

  if (fullScreen) {
    return (
      <div
        className='fixed inset-0 grid place-content-center bg-black'
        data-testid={SPINNER_MASK}
      >
        <SpinnerContent classNames={fullScreenCNs} />
        <BodyLock />
      </div>
    );
  }

  return <SpinnerContent />;

  // TODO: body lock; full screen vs not full screen spinner
};
export default Spinner;
