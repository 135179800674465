import * as auth from 'netlify-identity-widget';
import { useEffect } from 'react';
import { logout } from '../../state/auth/auth.slice';
import { useAppDispatch } from '../../hooks/redux';

/**
 * SettingsPage
 */
const SettingsPage = () => {
  const dispatch = useAppDispatch();

  // istanbul ignore next
  useEffect(() => {
    auth.on('logout', () => dispatch(logout()));

    return () => {
      auth.off('logout');
    };
  }, [dispatch]);

  return (
    <div className='text-neutral-300'>
      <h1>SettingsPage</h1>
      <button onClick={auth.logout}>Logout</button>
    </div>
  );
};
export default SettingsPage;
