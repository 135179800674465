import { DefaultToastOptions } from 'react-hot-toast';
import theme from '../theme';

/**
 * Toast
 */
export const toastOptions: DefaultToastOptions = {
  success: {
    icon: '✅',
    style: {
      backgroundColor: theme.colors.green[900],
      borderWidth: theme.borderWidth[2],
      borderColor: theme.colors.green[500],
      color: theme.colors.neutral[300],
    },
  },
  error: {
    icon: '❌',
    style: {
      backgroundColor: theme.colors.neutral[800],
      borderWidth: theme.borderWidth[2],
      borderColor: theme.colors.red[700],
      color: theme.colors.neutral[300],
    },
  },
};

/**
 * URL
 */
export enum QueryParamKey {
  TransactionsMonth = 'transactionsMonth',
  TransactionsTab = 'transactionsTab',
}

export enum TransactionsTab {
  Daily = 'Daily',
  Summary = 'Summary',
}

export const REPLACE = { replace: true };

/**
 * HTTP statuses
 */
export enum STATE_STATUS {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}
