import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface LoadingState {
  data: {
    isLoading: boolean;
  };
}

const initialState: LoadingState = {
  data: {
    isLoading: true,
  },
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.data.isLoading = action.payload;
    },
  },
});

/**
 * Reducer
 */
export const loadingReducer = loadingSlice.reducer;

/**
 * Actions
 */
export const { toggleLoading } = loadingSlice.actions;

/**
 * Selectors
 */
export const selectIsLoading = (state: RootState) =>
  state.loading.data.isLoading;
