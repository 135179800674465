import { PropsWithChildren, useEffect, useState } from 'react';
import * as auth from 'netlify-identity-widget';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  checkHealth,
  selectCheckHealth,
} from '../../state/health/health.slice';
import { STATE_STATUS } from '../../utils/constants';
import { selectErrorMessage } from '../../state/error/error.slice';
import Oops from '../Oops/Oops';
import { toggleLoading } from '../../state/loading/loading.slice';
import { setUser } from '../../state/auth/auth.slice';

/**
 * AppInit component
 */
const AppInit = ({ children }: PropsWithChildren) => {
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const dispatch = useAppDispatch();
  const errorMsg = useAppSelector(selectErrorMessage);
  const checkHealthStatus = useAppSelector(selectCheckHealth);

  /**
   * Init on mount
   */
  useEffect(() => {
    const checkHealthPromise = dispatch(checkHealth());

    return () => {
      checkHealthPromise.abort();
    };
  }, [dispatch]);

  /**
   * Get user (e.g., on refresh)
   */
  useEffect(() => {
    if (checkHealthStatus === STATE_STATUS.FULFILLED) {
      const user = auth.currentUser();

      if (user) {
        dispatch(setUser(JSON.parse(JSON.stringify(user))));
      }
    }
  }, [dispatch, checkHealthStatus]);

  /**
   * Wait until init requests have settled
   */
  useEffect(() => {
    const areAllFulfilled = [checkHealthStatus].every(status =>
      [STATE_STATUS.FULFILLED].includes(status),
    );

    if (errorMsg) {
      dispatch(toggleLoading(false));
    } else if (areAllFulfilled) {
      setIsAppInitialized(true);
      dispatch(toggleLoading(false));
    }
  }, [dispatch, checkHealthStatus, errorMsg]);

  if (errorMsg) {
    return <Oops errorMessage={errorMsg} />;
  }

  return isAppInitialized ? <>{children}</> : null;
};

export default AppInit;
