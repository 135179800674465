import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../state/store';

/**
 * Redux dispatch wrapper
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * Typed Redux selector hook
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
