import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { authReducer } from './auth/auth.slice';
import { healthReducer } from './health/health.slice';
import { errorReducer } from './error/error.slice';
import { loadingReducer } from './loading/loading.slice';

export const reducer = {
  loading: loadingReducer,
  error: errorReducer,
  health: healthReducer,
  auth: authReducer,
};

export const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppStore = typeof store;
