import { http } from '../../http/http';

/**
 * Check the health of the server
 */
const checkHealth = (signal: AbortSignal) =>
  http.get('/api/health', { signal });

export const healthAPI = {
  checkHealth,
};
