import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'netlify-identity-widget';
import { RootState } from '../store';

interface AuthState {
  user: null | User;
}

const initialState: AuthState = {
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /**
     * Set user (e.g., on refresh)
     */
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    /**
     * Login action
     */
    login(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    /**
     * Logout action
     */
    logout(state) {
      state.user = null;
    },
  },
});

/**
 * Selectors
 */
export const selectUser = (state: RootState) => state.auth.user;

export const { setUser, login, logout } = authSlice.actions;
export const authReducer = authSlice.reducer;
