import { ReactComponent as Plus } from '../../images/plus-circle.svg';
import { ReactComponent as Spreadsheet } from '../../images/table-cells.svg';
import { ReactComponent as Building } from '../../images/building-library.svg';
import { ReactComponent as Cog } from '../../images/cog.svg';
import { ReactComponent as SearchIcon } from '../../images/search.svg';
import { ReactComponent as ChevronLeft } from '../../images/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../images/chevron-right.svg';
import { ReactComponent as LogoAndText } from '../../images/budgetrecon-logo-and-text.svg';
import { ReactComponent as Logo } from '../../images/budgetrecon-logo.svg';
import { ReactComponent as DollarSign } from '../../images/budgetrecon-just-dollar-sign.svg';
import { ReactComponent as Sight } from '../../images/budgetrecon-just-sight.svg';

const Icons = {
  Plus,
  Spreadsheet,
  Building,
  Cog,
  SearchIcon,
  ChevronLeft,
  ChevronRight,
  LogoAndText,
  Logo,
  DollarSign,
  Sight,
};

export default Icons;
